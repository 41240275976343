.markdown p {
  margin-bottom: 16px;
}

.markdown li {
  margin-left: 18px;
}

.markdown ul {
  margin-bottom: 16px;
}
